/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useEffect, useState } from "react"
import { Spinner, Badge } from "@theme-ui/components"
import Container from "../components/container"
import Text from "../components/text"
import Column from "../components/column"
import Row from "../components/row"
import SiteVisitIcon from "../images/site-visit-icon.png"
import LotAccessIcon from "../images/lot-access-icon.png"

const LotAssessmentReport = props => {
  const id = props.location.search.split("=")[1]
  const [data, setData] = useState(null)
  const [error, setError] = useState("")
  useEffect(() => {
    if (id) {
      fetch(
        `https://graphql.contentful.com/content/v1/spaces/c0wa9gntjrmf/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer LLy_WFME4RaMKzr5wuYQ8AbodJX8zjSwrnFIPO5fqfc`,
          },
          body: JSON.stringify({
            query: `
              query($id: String!) {
                lotAssessment(id: $id){
                  firstName
                  lastName
                  email
                  propertyAddress
                  lotAssessmentData
                }
              }
      `,
            variables: {
              id: id,
            },
          }),
        }
      )
        .then(res => res.json())
        .then(result => {
          if (result.data.lotAssessment === null) {
            setError("Please provide a valid URL")
            return
          }

          setData(result.data)
        })
        .catch(err => {
          setError("Please provide a valid URL")
        })
    }
  }, [])

  const renderTable = () => {
    let zone_check_passed =
      data.lotAssessment.lotAssessmentData?.zone_check_passed
    if (zone_check_passed === "True") {
      return (
        <table id="table001" className="Basic-Table">
          <colgroup>
            <col className="_idGenTableRowColumn-1" />
            <col className="_idGenTableRowColumn-2" />
            <col className="_idGenTableRowColumn-3" />
          </colgroup>
          <thead>
            <tr className="Basic-Table _idGenTableRowColumn-4">
              <td className="Basic-Table CellOverride-1"></td>
              <td className="Basic-Table CellOverride-2">
                <Text
                  className="Basic-Paragraph"
                  type="p"
                  customStyle={{ fontWeight: "bold" }}
                >
                  You Selected
                </Text>
              </td>
              <td className="Basic-Table CellOverride-3">
                <Text
                  className="Basic-Paragraph"
                  type="p"
                  customStyle={{ fontWeight: "bold" }}
                >
                  Initial Findings
                </Text>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="Basic-Table _idGenTableRowColumn-5">
              <td className="Basic-Table CellOverride-4 _idGenCellOverride-1">
                <Text
                  className="Basic-Paragraph"
                  type="p"
                  customStyle={{ fontWeight: "bold" }}
                >
                  Project Type
                </Text>
              </td>
              <td className="Basic-Table CellOverride-5 _idGenCellOverride-1">
                <Text
                  className="Basic-Paragraph"
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  {data.lotAssessment.lotAssessmentData.selected_propertytype}
                </Text>
              </td>
              <td className="Basic-Table CellOverride-6 _idGenCellOverride-1">
                <Text
                  className="Basic-Paragraph"
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  {data.lotAssessment.lotAssessmentData.check_propertytype}
                </Text>
                <Text
                  className="Basic-Paragraph"
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  To know for sure, a zoning report is required. Please see
                  below for next steps.
                </Text>
              </td>
            </tr>
            <tr className="Basic-Table _idGenTableRowColumn-6">
              <td className="Basic-Table CellOverride-7">
                <Text
                  className="Basic-Paragraph "
                  type="p"
                  customStyle={{ fontWeight: "bold" }}
                >
                  Project Size
                </Text>
              </td>
              <td className="Basic-Table CellOverride-8">
                <Text
                  className="Basic-Paragraph"
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  {data.lotAssessment.lotAssessmentData.selected_pprojectsize}
                </Text>
              </td>
              <td className="Basic-Table CellOverride-9">
                <Text
                  className="Basic-Paragraph "
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  {data.lotAssessment.lotAssessmentData.check_pprojectsize}
                </Text>
                <Text
                  className="Basic-Paragraph "
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  To know for sure, a zoning report is required. Please see
                  below for next steps.
                </Text>
              </td>
            </tr>
            <tr className="Basic-Table _idGenTableRowColumn-7">
              <td className="Basic-Table CellOverride-7">
                <Text className="Basic-Paragraph ">
                  <span className="CharOverride-3">Project Budget</span>
                </Text>
              </td>
              <td className="Basic-Table CellOverride-8">
                <Text
                  className="Basic-Paragraph "
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  {data.lotAssessment.lotAssessmentData.selected_projectbudget}
                </Text>
              </td>
              <td className="Basic-Table CellOverride-9">
                <Text
                  className="Basic-Paragraph "
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  For projects of this size, Plant Prefab factory fabrication
                  costs typically range between{" "}
                  {data.lotAssessment.lotAssessmentData.check_projectbudget}.
                  You should also budget for roughly $300 - $400 per square foot
                  in site and soft costs.
                </Text>
                <Text
                  className="Basic-Paragraph "
                  type="p"
                  customStyle={{
                    fontWeight: "normal",
                    fontStyle: "normal",
                    color: "#787878",
                  }}
                >
                  Please see our{" "}
                  <a href="https://www.plantprefab.com/">
                    <span className="Hyperlink CharOverride-2">
                      pricing page
                    </span>
                  </a>{" "}
                  to learn how we help you translate your project ideas into a
                  cost estimate.
                </Text>
              </td>
            </tr>
          </tbody>
        </table>
      )
    } else {
      return (
        <Text className="Basic-Paragraph ">
          <span className="CharOverride-7">
            Manual research required: This virtual lot check tool was designed
            for single-family projects in residential zoning areas, so we’ll
            need to check your project fit the old-fashioned way (by hand)! Our
            Client Services team will email you within a few business days.
          </span>
        </Text>
      )
    }
  }

  const renderReport = () => {
    let isWidth = /\d/.test(data.lotAssessment.lotAssessmentData.lot_width)
    let isHeight = /\d/.test(data.lotAssessment.lotAssessmentData.lot_depth)
    return (
      <div>
        <Container
          customStyle={{
            pb: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
            ],
          }}
        >
          <div className="_idGenObjectLayout-1 logo">
            <div id="_idContainer001" className="_idGenObjectStyleOverride-1">
              <img
                className="_idGenObjectAttribute-1"
                src="PP_Logo_Full-Color.jpg"
                alt=""
              />
            </div>
          </div>
          <div id="_idContainer002" className="Basic-Text-Frame">
            <Text
              type="h1"
              customStyle={{
                fontSize: [33, 56, 56, 56],
                mb: [10],
                display: "inline-block",
              }}
            >
              Virtual Lot Check
            </Text>
            <Badge
              variant="circle"
              sx={{ top: ["16%", "14%"], position: "absolute" }}
            >
              Beta
            </Badge>
            <Text className="Basic-Paragraph ">
              Please note that this lot check was conducted by software, as a
              quick way for you to see what might be possible on your chosen
              property. If you’d like to proceed, instructions for gathering
              more specific information are listed at the end of this report.
              <br />
              <br /> We look forward to working with you!
            </Text>
          </div>
        </Container>
        <Container
          customStyle={{
            pb: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
            ],
          }}
        >
          <div id="_idContainer005" className="_idGenObjectStyleOverride-1">
            <Text className="Basic-Paragraph ">
              <span className="CharOverride-1">Parcel Data</span>
            </Text>
            <Text className="Basic-Paragraph ">
              <span className="CharOverride-5">
                <img
                  className="_idGenObjectAttribute-2"
                  src={data.lotAssessment.lotAssessmentData.googleMapImage}
                  alt=""
                />
              </span>
            </Text>
          </div>
          <div id="_idContainer006" className="Basic-Text-Frame">
            <Text className="Basic-Paragraph ">
              <span className="CharOverride-3">Note: </span>
              <span className="CharOverride-2">
                This information is pulled from available public sources. As
                such, it should be considered for general reference only.
              </span>
            </Text>
            <Text className="Basic-Paragraph ">
              <span className="CharOverride-6 bold_headings">Address</span>
              <br />
              <span className="CharOverride-7">
                {/* {data.lotAssessment.lotAssessmentData.address} */}
                {data.lotAssessment.propertyAddress}
              </span>
            </Text>
            <Text className="Basic-Paragraph ">
              <span className="CharOverride-6 bold_headings">
                Assessor’s Parcel Number (APN)
              </span>
              <br />
              <span className="CharOverride-7">
                {data.lotAssessment.lotAssessmentData.apn}
              </span>
            </Text>
            <Text className="Basic-Paragraph ">
              <span className="CharOverride-6 bold_headings">Lot Size</span>
              <br />
              <span className="CharOverride-7">
                {parseInt(
                  data.lotAssessment.lotAssessmentData.lot_size
                ).toLocaleString("en-US")}{" "}
                square feet
              </span>
            </Text>

            {(isWidth || isHeight) && (
              <>
                <Text className="Basic-Paragraph ">
                  <span className="CharOverride-6 bold_headings">
                    Lot Dimensions
                  </span>
                  <br />
                  {isWidth && (
                    <>
                      <span className="CharOverride-7">
                        Width: {data.lotAssessment.lotAssessmentData.lot_width}{" "}
                        feet
                      </span>
                      <br />
                    </>
                  )}
                  {isHeight && (
                    <span className="CharOverride-7">
                      Depth: {data.lotAssessment.lotAssessmentData.lot_depth}{" "}
                      feet
                    </span>
                  )}{" "}
                </Text>
              </>
            )}
            <Text className="Basic-Paragraph">
              <span className="CharOverride-6 bold_headings">
                Property Zone
              </span>
              <br />
              <span className="CharOverride-7">
                {data.lotAssessment.lotAssessmentData.zone}
              </span>
            </Text>
            <Text className="Basic-Paragraph ">
              <span className="CharOverride-7">
                Zoning dictates the type of structures that can be built, as
                well as things like height restrictions, setback requirements
                (minimum distance from property boundaries), and allowed
                residential floor area (maximum square footage of all living
                spaces).
              </span>
            </Text>

            <Text className="Basic-Paragraph ">
              <span className="CharOverride-6 bold_headings">
                Allowable Residential Floor Area
              </span>
              <Text className="Basic-Paragraph ">
                <span className="CharOverride-7">
                  Please note that the figures below are based on statewide
                  averages; rural lots and lots located in urban sub-districts
                  and/or special plan areas often have different allowances. A
                  zoning report is required to obtain precise information for
                  your specific lot.
                </span>
               
              </Text>
              <br/>
              <span className="CharOverride-7">
                Allowable Residential Floor Area: Approximately{" "}
                {parseInt(
                  data.lotAssessment.lotAssessmentData.allowed_floor_area
                ).toLocaleString("en-US")}{" "}
                square feet
              </span>
              <br />
              <span className="CharOverride-7">
                Existing Residential Floor Area:{" "}
                {data.lotAssessment.lotAssessmentData.existing_floor_area
                  ? `Approximately ${parseInt(
                      data.lotAssessment.lotAssessmentData.existing_floor_area
                    ).toLocaleString("en-US")} square feet`
                  : "No existing structures found"}{" "}
              </span>
              <br />
              <span className="CharOverride-7">
                Remaining Allowable Residential Floor Area:{" "}
                {data.lotAssessment.lotAssessmentData.remaining_floor_area &&
                data.lotAssessment.lotAssessmentData.remaining_floor_area < 0
                  ? "It looks like there is no remaining floor area"
                  : `Approximately ${parseInt(
                      data.lotAssessment.lotAssessmentData.remaining_floor_area
                    ).toLocaleString("en-US")}  square feet*`}
              </span>
            </Text>
            {/* <Text className="Basic-Paragraph ">
              <span className="CharOverride-6 bold_headings">
                Existing Residential Floor Area
              </span>
              <br />
              <span className="CharOverride-7">
                {data.lotAssessment.lotAssessmentData.existing_floor_area
                  ? `Approximately ${parseInt(
                      data.lotAssessment.lotAssessmentData.existing_floor_area
                    ).toLocaleString("en-US")} square feet*`
                  : "No existing structures found"}{" "}
              </span>
            </Text> */}

            {/* <Text className="Basic-Paragraph ">
              <span className="CharOverride-6 bold_headings">
                Remaining Allowable Residential Floor Area
              </span>
              <br />
              <span className="CharOverride-7">
                {data.lotAssessment.lotAssessmentData.remaining_floor_area &&
                data.lotAssessment.lotAssessmentData.remaining_floor_area < 0
                  ? "It looks like there is no remaining floor area"
                  : `Approximately ${parseInt(
                      data.lotAssessment.lotAssessmentData.remaining_floor_area
                    ).toLocaleString("en-US")}  square feet*`}
              </span>
            </Text> */}
            {/* <Text className="Basic-Paragraph ">
              <span className="CharOverride-7">
                *Based on statewide averages. Please note that many cities have
                sub-districts and/or special plan areas with different
                requirements. A zoning report is required to obtain precise
                information for your property.
              </span>
            </Text> */}
            <Text
              className="Basic-Paragraph  initial_check_p"
              type="h2"
              customStyle={{
                mt: 50,
                mb: 20,
                color: "#31aabb",
                fontWeight: "bold",
                fontSize: [45],
              }}
            >
              Initial Findings
            </Text>
            {renderTable()}
          </div>
          <div id="_idContainer007">
            <div className="_idGenObjectStyleOverride-1">
              <Text className="Basic-Paragraph ">
                <span className="CharOverride-1">Next Steps</span>
              </Text>
            </div>
            <Row>
              <Column size={[12, 12, 2]}>
                <img
                  src={LotAccessIcon}
                  alt="lot access"
                  className="next_step_logo"
                />
              </Column>
              <Column size={[12, 12, 10]}>
                <div id="_idContainer027" className="Basic-Text-Frame">
                  <Text className="Basic-Paragraph ParaOverride-3">
                    <span className="CharOverride-6 bold_headings">
                      Lot Access Assessment
                    </span>
                  </Text>
                  <Text className="Basic-Paragraph ">
                    <span className="CharOverride-2 CharOverride-9">
                      Your next step is to inspect your lot and the surrounding
                      area for any possible impediments that might preclude the
                      delivery or installation of prefabricated components.
                      We’ve created a brief questionnaire to guide you through
                      your inspection. Once you complete the questionnaire,
                      we’ll review it, potentially ask for additional
                      information and photos, and discuss our findings with you.
                    </span>
                  </Text>
                  <Text className="Basic-Paragraph ">
                    <a
                      href="https://assets.ctfassets.net/c0wa9gntjrmf/2jQhAcpi5Af4zWFFS7hBDt/e4c28ed6606dd209a59684f88ac1787f/Lot_Access_Assessment.pdf"
                      target="__blank"
                    >
                      <span className="CharOverride-4">
                        Download questionnaire{" "}
                      </span>
                      <span className="CharOverride-4">&gt;</span>
                    </a>
                  </Text>
                </div>
              </Column>
            </Row>
            <Row>
              <Column size={[12, 12, 2]}>
                <img
                  src={SiteVisitIcon}
                  alt="lot access"
                  className="next_step_logo"
                />
              </Column>
              <Column size={[12, 12, 10]}>
                <div id="_idContainer027" className="Basic-Text-Frame">
                  <Text className="Basic-Paragraph ParaOverride-3">
                    <span className="CharOverride-6 bold_headings">
                      Site Visit &amp; Zoning Report
                    </span>
                  </Text>
                  <Text className="Basic-Paragraph ">
                    <span className="CharOverride-2 CharOverride-9">
                      If no issues are identified in the lot access assessment,
                      you will be able to request a site visit and zoning
                      report. This is a paid service (performed by Plant Prefab)
                      that will define exactly what can be built on your
                      property and how it should be constructed, delivered, and
                      installed to meet the specific zoning requirements and
                      unique conditions of your lot.
                    </span>
                  </Text>
                </div>
              </Column>
            </Row>
          </div>
        </Container>
      </div>
    )
  }

  if (data === null && error) return <div>{error}</div>
  if (data === null)
    return (
      <Container
        customStyle={{
          pb: 50,
          pt: 50,
          textAlign: "center",
        }}
      >
        <div>
          <Spinner />
        </div>
      </Container>
    )
  if (!id) return <div>Data not found</div>

  return (
    <React.Fragment>
      {/* <Page data={data.contentfulPage}>{renderReport()}</Page> */}
      {renderReport()}
    </React.Fragment>
  )
}

export default LotAssessmentReport
